import { render, staticRenderFns } from "./Default.vue?vue&type=template&id=59b90dd5&"
var script = {}
import style0 from "@/assets/less/global.less?vue&type=style&index=0&prod&lang=less&"
import style1 from "@/assets/css/main-flex.css?vue&type=style&index=1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports